import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home',
    component: () => import('@/views/index.vue'),
    children: [
      {
        path: '/home',
        name: 'Home',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "home" */ '../views/home.vue')
      },
      {
        path: '/productcenter',
        name: 'ProductCenter',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "productCenter" */ '../views/productCenter.vue')
      },
      {
        path: '/login',
        name: 'Login',
        meta: {
          type: 'special' // 判断是否需要展示底部的组件，如果是登录页面不需要展示底部组件
        },
        component: () => import(/* webpackChunkName: "login" */ '../views/login.vue')
      },
      {
        path: '/register',
        name: 'Register',
        meta: {
          type: 'special' // 判断是否需要展示底部的组件，如果是登录页面不需要展示底部组件
        },
        component: () => import(/* webpackChunkName: "register" */ '../views/register.vue')
      },
      {
        path: '/deepBlue',
        name: 'DeepBlue',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "deepBlue" */ '../views/deepBlue.vue')
      },
      {
        path: '/blueReport',
        name: 'BlueReport',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "blueReport" */ '../views/blueReport.vue')
      },
      {
        path: '/OpenAPI',
        name: 'OpenAPI',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "openAPI" */ '../views/openAPI.vue')
      },
      {
        path: '/prod',
        name: 'prod',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "prod" */ '../views/prod.vue')
      },
      {
        path: '/patE',
        name: 'patE',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "patE" */ '../views/patE.vue')
      },
      {
        path: '/thinkTanks',
        name: 'ThinkTanks',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "rank" */ '../views/thinkTanks.vue')
      },
      {
        path: '/media',
        name: 'media',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "media" */ '../views/media.vue')
      },
      {
        path: '/aboutUs',
        name: 'AboutUs',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "aboutus" */ '../views/aboutUs.vue')
      },
      {
        path: '/contactUs',
        name: 'ContactUs',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "contactus" */ '../views/contactUs.vue')
      },
      {
        path: '/financialService',
        name: 'FinancialService',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "financialService" */ '../views/financialService.vue')
      },
      {
        path: '/aerialView',
        name: 'AerialView',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "aerialView" */ '../views/aerialView.vue')
      },
      {
        path: '/bigData',
        name: 'BigData',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "bigData" */ '../views/bigData.vue')
      },
      {
        path: '/patentNavigation',
        name: 'PatentNavigation',
        meta: {
          type: 'default'
        },
        component: () => import(/* webpackChunkName: "patentNavigation" */ '../views/patentNavigation.vue')
      },
      {
        path: '/accountCenter',
        name: 'AccountCenter',
        meta: {
          type: 'default'
        },
        redirect: '/accountCenter/accountInformation',
        component: () => import(/* webpackChunkName: "accountCenter" */ '../views/accountCenter/index.vue'),
        children: [
          {
            path: '/accountCenter/accountInformation',
            name: 'AccountInformation',
            meta: {
              type: 'default',
              title: '账号信息'
            },
            component: () => import(/* webpackChunkName: "accountCenter" */ '../views/accountCenter/accountInformation/index.vue'),
          },
          {
            path: '/accountCenter/accountManagement',
            name: 'AccountManagement',
            meta: {
              type: 'default',
              title: '账号管理'
            },
            component: () => import(/* webpackChunkName: "accountCenter" */ '../views/accountCenter/accountManagement/index.vue'),
          },
          {
            path: '/accountCenter/myList',
            name: 'MyList',
            meta: {
              type: 'default',
              title: '我的名单'
            },
            component: () => import(/* webpackChunkName: "accountCenter" */ '../views/accountCenter/myList/index.vue'),
          },
          {
            path: '/accountCenter/myList/allocationList',
            name: 'AllocationList',
            meta: {
              type: 'default',
              toBack: true
            },
            component: () => import(/* webpackChunkName: "accountCenter" */ '../views/accountCenter/allocationList/index.vue'),
          },
          {
            path: '/accountCenter/downloadCenter',
            name: 'DownloadCenter',
            meta: {
              type: 'default',
              title: '下载中心'
            },
            component: () => import(/* webpackChunkName: "accountCenter" */ '../views/accountCenter/downloadCenter/index.vue'),
          },
          {
            path: '/accountCenter/myAttention',
            name: 'MyAttention',
            meta: {
              type: 'default',
              title: '我的关注'
            },
            component: () => import(/* webpackChunkName: "accountCenter" */ '../views/accountCenter/myAttention/index.vue'),
          },
          {
            path: '/accountCenter/productEquity',
            name: 'ProductEquity',
            meta: {
              type: 'default',
              title: '产品权益'
            },
            component: () => import(/* webpackChunkName: "accountCenter" */ '../views/accountCenter/productEquity/index.vue'),
          },
          {
            path: '/accountCenter/msgCenter',
            name: 'MsgCenter',
            meta: {
              type: 'default',
              title: '消息中心'
            },
            component: () => import(/* webpackChunkName: "msgCenter" */ '../views/accountCenter/msgCenter/index.vue'),
          },
          {
            path: '/accountCenter/msgCenter/enterpriseAllMsg',
            name: 'EnterpriseAllMsg',
            meta: {
              type: 'default',
              toBack: true
            },
            component: () => import(/* webpackChunkName: "accountCenter" */ '../views/accountCenter/enterpriseAllMsg/index.vue'),
          },
          {
            path: '/accountCenter/monitorManage',
            name: 'MonitorManage',
            meta: {
              type: 'default',
              title: '管理监控企业'
            },
            component: () => import(/* webpackChunkName: "monitorManage" */ '../views/accountCenter/monitorManage/index.vue'),
          },
        ]
      },
    ]
  },
  {
    path: '/searchPackage',
    name: 'SearchPackage',
    meta: {
      type: 'default',
      title: '套餐查询'
    },
    component: () => import(/* webpackChunkName: "aboutus" */ '../views/searchPackage/index.vue')
  },
  {
    path: '/hardTecReportList',
    name: 'HardTecReportList',
    meta: {
      type: 'default',
      title: '硬科技报告'
    },
    component: () => import(/* webpackChunkName: "HardTecReportList" */ '../views/hardTecReportList/index.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

router.beforeEach((to, from, next) => {

  if (from.path == '/') {
    //从别的系统跳转到官网
    if (to.query.token) {
      // 移除URL中的token参数  
      const newPath = to.fullPath.replace(/(\?|&)token=\w+/, '');
      next(newPath);
    } else {
      next()
    }
  } else {
    if (store.state.isLogin) {
      to.path === '/login' ? next('/home') : next()
    } else {
      to.path === '/productcenter' ? next('/home') : next()
    }
  }
})

export default router
