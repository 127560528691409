<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getUrlParams } from "./utils/index"
export default {
  created() {
    const token = getUrlParams('token')
    if (token) {
      this.$store.state.token = token
      this.$store.state.isLogin = true
      this.$store.dispatch('getUserInfo')
    }
  }
}
</script>

<style lang="scss">
@import './assets/iconfont/iconfont.css';
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: hidden;
  min-height: 100vh;
  background-color: #f5f6f9;
}

body {
  margin: 0;
}

img {
  font-size: 0;
  display: block;
  height: auto;
  image-rendering: -webkit-optimize-contrast;
}

li {
  list-style: none;
}

a,
a:link a:hover,
a:active {
  text-decoration: none;
}

a:link,
a:visited {
  color: #333333;
}

p {
  line-height: 1;
  margin: 0;
}

// 滚动条
::-webkit-scrollbar {
  width: 2px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #eee;
  border-radius: 8px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bababa;
}

::-webkit-scrollbar-thumb:active {
  background-color: #bababa;
}
</style>
